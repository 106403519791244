<template v-if="isAuthenticated"></template>
<script>
    import session from '@/api/session';
    import { mapGetters } from 'vuex'

    export default {
        name: "Tour",
        props: {
            tips: {
                type: Object,
                required: true
            },
            t_name: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                filteredSteps: []
            }
        },
        computed: {
            ...mapGetters('auth', [
                'isAuthenticated',
            ]),
        },
        methods: {
            runTour(steps = []) {
                this.$nextTick(() => {
                    const tour = this.$shepherd({
                        useModalOverlay: true,
                        defaultStepOptions: {
                            scrollTo: true,
                            classes: 'v-sheperd'
                        },
                        classPrefix: 'v-sheperd-'
                    });

                    steps.forEach(step => {
                        const {element, position, text} = step;
                        tour.addStep({
                            attachTo: {element: element, on: position || 'right'},
                            text: text,
                            buttons: [
                                {
                                    text: 'Назад',
                                    action: tour.back,
                                    classes: `v-btn v-btn--transparent`,
                                    disabled: step.isFirst
                                },
                                {
                                    text: 'Далее',
                                    action: () => {
                                        tour.next();
                                        this.completeStep(step.hash);
                                    },
                                    classes: 'v-btn'
                                }
                            ],
                        });
                    })

                    tour.start();
                });
            },
            async completeStep(hash) {
                try {
                    const request = await session.post(`/api/v1/hint/`, {
                        passed: {
                            [this.t_name]: [hash]
                        }
                    });
                } catch (error) {
                    console.error(error)
                }
            }
        },
        async mounted() {
            const response = await session.get(`/api/v1/hint/`),
                completedHashes = response.data[this.t_name] || [],
                tipsList = this.tips.list,
                notCompletedTips = tipsList.filter(tip => !completedHashes.includes(tip.hash) && !tip.isDisabled)

            if (notCompletedTips.length) {
                notCompletedTips[0].isFirst = true;
                this.runTour(notCompletedTips);
            }
        }
    }
</script>

<style lang="scss">
    @import '~shepherd.js/dist/css/shepherd.css';
    @import "#sass/v-style";

    .v-sheperd {
        /*margin-left: 15px !important;*/
        max-width: 270px;
        @media (max-width: 768px) {
            max-width: 235px;
        }

        .shepherd {
            &-content {
                padding: 0;
                border: 4px solid #FBC04F;
            }

            &-text {
                padding: 20px;
                color: #000;
            }

            &-footer {
                padding: 20px;
                margin-top: 20px;
                border-top: 1px solid #E0E3F0;
            }

            &-arrow {
                z-index: 5;
            }
        }
    }

    .shepherd-button {
        &:hover {
            background: $gold !important;
            color: #000 !important;
            opacity: 0.7 !important;
        }

        &.v-btn--transparent:hover {
            background: #FFF !important;
        }
    }

    /* Удаление затемнённого фона */
    .shepherd-modal-is-visible.shepherd-modal-overlay-container {
        display: none;
    }

    /* Стили для рамки и стрелочки тултипа */
    .shepherd-element[data-popper-placement^=right] {
        margin-left: 15px !important;

        .shepherd {
            &-arrow {
                left: -7.3px !important;

                &::before {
                    border-left: 4px solid #FBC04F;
                    border-bottom: 4px solid #FBC04F;
                }
            }
        }
    }

    .shepherd-element[data-popper-placement^=left] {
        margin-right: 15px !important;

        .shepherd {
            &-arrow {
                right: -7.3px !important;

                &::before {
                    border-right: 4px solid #FBC04F;
                    border-top: 4px solid #FBC04F;
                }
            }
        }
    }

    .shepherd-element[data-popper-placement^=top] {
        margin-bottom: 15px !important;

        .shepherd {
            &-arrow {
                bottom: -7.3px !important;

                &::before {
                    border-bottom: 4px solid #FBC04F;
                    border-right: 4px solid #FBC04F;
                }
            }
        }
    }

    .shepherd-element[data-popper-placement^=bottom] {
        margin-top: 15px !important;

        .shepherd {
            &-arrow {
                top: -7.3px !important;

                &::before {
                    border-top: 4px solid #FBC04F;
                    border-left: 4px solid #FBC04F;
                }
            }
        }
    }
</style>
